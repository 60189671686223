import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';

// actions
import { getStateFromCity } from '@/api';

const uiSchemaKeys = [
	'mobileUiSchema',
	'tabletUiSchema',
	'modalUiSchema',
	'desktopUiSchema',
];

const formPageConfigKeysToReplaceInForm = [
	'submitButtonText',
	'title',
	'description',
];

const removeElementFromUISchemaByFieldKey = (elements, fieldKeyToRemove) => {
	if (elements.length === 0) return;

	let currentElement;
	for (let i = 0; i < elements.length; i++) {
		currentElement = elements[i];

		if (currentElement.type.trim().toLowerCase() !== 'control') {
			removeElementFromUISchemaByFieldKey(
				currentElement.elements,
				fieldKeyToRemove
			);
		} else if (currentElement.scope.includes(fieldKeyToRemove)) {
			elements.splice(i, 1);

			// push empty layout element so remaining fields in row shift to left
			if (elements.length > 0) {
				elements.push({ type: 'VerticalLayout', elements: [] });
			}

			break;
		}
	}
};

// FIXME: Fix the approach in this function (remove properties from schema and required, traverse uischema and remove all keys at once)
const removeFieldsFromForm = (form, fields = []) => {
	fields.forEach((fieldKeyToRemove) => {
		delete form.controlSchema.properties[fieldKeyToRemove];

		if (form.controlSchema.required) {
			form.controlSchema.required = form.controlSchema.required.filter(
				(key) => !fields.includes(key)
			);
		}

		// remove field from each type of ui schema
		uiSchemaKeys.forEach((uiSchemaKey) => {
			if (form[uiSchemaKey]) {
				removeElementFromUISchemaByFieldKey(
					form[uiSchemaKey].elements,
					fieldKeyToRemove
				);
			}
		});
	});

	return form;
};

export const applyConfigToForm = (
	form,
	formPageConfig = {},
	globalFormConfig = {},
	formPageConstants = {}
) => {
	// priority: page level schema config > global form config > form's own schema config
	form.controlSchema.properties = {
		...form.controlSchema.properties,
		...globalFormConfig.schemaConstants,
		...formPageConfig.schema,
	};

	formPageConfigKeysToReplaceInForm.forEach((key) => {
		if (formPageConfig[key]) form[key] = formPageConfig[key];
	});

	if (form.redirectToThankYouPage) {
		form.thankYouRedirectionLink = getThankYouRedirectLink(
			form.redirectToThankYouPage
		);
	}

	return removeFieldsFromForm(form, Object.keys(formPageConstants));
};

export const getDefaultValuesFromSearch = (form, searchQuery = {}) => {
	const { properties: controlSchemaProperties } = form.controlSchema;

	let defaultFormValues = Object.keys(controlSchemaProperties).reduce(
		(acc, fieldKey) => {
			let valueToAssign = null;
			let currentFieldEnumOptions = controlSchemaProperties[fieldKey].enum;
			let currentFieldType = controlSchemaProperties[fieldKey].type;

			if (searchQuery[fieldKey]) {
				valueToAssign = searchQuery[fieldKey];
			}

			if (
				currentFieldEnumOptions &&
				currentFieldEnumOptions.length &&
				currentFieldType === 'string' &&
				valueToAssign
			) {
				valueToAssign = currentFieldEnumOptions.find((val) => {
					return val.toLowerCase() === valueToAssign.toLowerCase();
				});
			}

			return valueToAssign ? { ...acc, [fieldKey]: valueToAssign } : acc;
		},
		{}
	);

	return defaultFormValues;
};

export const getDefaultUserValues = (form, userData = {}) => {
	const defaultFormValues = {};
	const { properties } = form.controlSchema;

	if (properties.name) {
		defaultFormValues.name = userData.firstName
			? userData.lastName
				? `${userData.firstName} ${userData.lastName}`
				: userData.firstName
			: '';
	}
	if (properties.first_name && userData.firstName) {
		defaultFormValues.first_name = userData.firstName;
	}
	if (properties.last_name && userData.lastName) {
		defaultFormValues.last_name = userData.lastName;
	}
	if (properties.email && userData.email) {
		defaultFormValues.email = userData.email;
	}
	if (properties.country_code) {
		defaultFormValues.country_code = userData.country_code || '+91';
	}
	if (properties.mobile && userData.mobile) {
		defaultFormValues.mobile = userData.mobile.slice(-10);
	}
	if (valueInProfile(properties.city, userData.city)) {
		defaultFormValues.city = userData.city;
	}
	const targetCountry = valueInProfile(
		properties.target_country,
		get(userData, 'desiredProfile.interest_countries'),
		true
	);
	if (targetCountry) {
		defaultFormValues.target_country = targetCountry;
	}
	if (properties.target_intake) {
		const intakeInProfile =
			get(userData, 'desiredProfile.intake_month') &&
			get(userData, 'desiredProfile.intake_year')
				? `${userData.desiredProfile.intake_month} ${userData.desiredProfile.intake_year}`
				: null;

		if (valueInProfile(properties.target_intake, intakeInProfile))
			defaultFormValues.target_intake = intakeInProfile;
	}

	return defaultFormValues;
};

export const getThankYouRedirectLink = (pageType) => {
	switch (pageType) {
		case 'none': {
			return '';
		}
		default: {
			return pageType;
		}
	}
};

export const getLeadVerifiedEventProps = (eventLabel) => ({
	eventLabel: eventLabel || '',
	eventName: 'LEAD_VERIFIED',
	eventAction: 'LEAD_VERIFIED_SUCCESS',
	eventCategory: 'LEAD',
	eventOrigin: 'WEBSITE',
});

export const getRecordPayload = async (
	formData,
	constants,
	pageConstants,
	whatsappOptIn = false,
	location
) => {
	const search = new URLSearchParams(location.search),
		data = {
			registrant: {
				...formData,
				whatsapp_opt_in: whatsappOptIn ? 'Opt-In' : 'Opt-Out',
			},
			utm_params: {
				...fromPairs([...search]),
				href: location.href,
				slug_href: location.pathname,
			},
		};

	if (formData.city && !formData.state) {
		try {
			const response = await getStateFromCity(formData.city);
			data.registrant.state = response.data.name;
		} catch (_) {
			// hadle error
		}
	}

	if (constants || pageConstants) {
		const _constants = constants || {},
			_pageConstants = pageConstants || {},
			_fair = data.fair || _pageConstants.fair || _constants.fair || '';

		let constantsByFair =
			_pageConstants.constantsByFair || _constants.constantsByFair;
		constantsByFair =
			(isPlainObject(constantsByFair) && constantsByFair[_fair]) || {};

		data.registrant = {
			..._constants,
			..._pageConstants,
			...constantsByFair,
			...data.registrant,
		};

		if (data.registrant.constantsByFair) {
			delete data.registrant.constantsByFair;
		}
	}

	return data;
};

const valueInProfile = (fieldProps, value, valueIsArray) => {
	if (!fieldProps || value === undefined || value === null) return null;

	const valueMap = {};
	if (valueIsArray) value.forEach((value) => (valueMap[value] = true));
	else valueMap[value] = true;

	if (fieldProps.enum) {
		let value = null;
		for (let i = 0; i < fieldProps.enum.length; i++) {
			if (valueMap[fieldProps.enum[i]]) {
				value = fieldProps.enum[i];
				break;
			}
		}
		return value;
	}

	return valueIsArray ? value[0] : value;
};

export const hasRequiredData = (
	form,
	profileData = {},
	excludeProperties = {}
) => {
	if (!form) return false;

	const requiredFields = form.controlSchema.required.reduce((data, key) => {
		if (!(key in excludeProperties)) data[key] = true;
		return data;
	}, {});

	const formData = getDefaultUserValues(form, profileData);
	let allFieldsExists = true;
	for (const key in requiredFields) {
		if (!formData[key]) {
			allFieldsExists = false;
			break;
		}
	}

	return allFieldsExists;
};
